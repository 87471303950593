<template>
  <div class="content ledger-list">
    <div class="form-content">
      <a-row :gutter="16">
        <a-col :span="6">
          <SelectAccountSet
            :placeholder="$t(`账簿`)"
            :code="formData.accountSetUnionCode"
            @change="changeAccountSet"
          />
        </a-col>
        <a-col :span="6">
          <a-input :disabled="true" :value="formData.businessUnitName" />
        </a-col>
        <a-col :span="6">
          <a-input
            readOnly
            :value="accountName"
            @click="showAccountModal"
            :placeholder="$t('科目名称')"
          />
        </a-col>
        <a-col :span="6">
          <a-select
            style="width: 100%"
            v-model="accountingPeriodStart"
            :placeholder="$t(`开始期间`)"
          >
            <a-select-option
              v-for="(item, index) in periodListStart"
              :key="index"
              :value="item.accounting_period_code"
            >
              {{ item.accounting_period_name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <a-select style="width: 100%" v-model="accountingPeriodEnd" :placeholder="$t(`结束期间`)">
            <a-select-option
              v-for="(item, index) in periodListEnd"
              :key="index"
              :value="item.accounting_period_code"
            >
              {{ item.accounting_period_name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="18">
          <div class="option-btns">
            <span class="text-link fz16" @click="showDimensionModal">
              {{ $t('增行') }}
            </span>
            <span class="text-link fz16 ml20" @click="deleteDimension">
              {{ $t('删行') }}
            </span>
          </div>
          <CommonTable
            class="dimension-table"
            :columns.sync="dimensionColumns"
            :dataSource="formData.dimensionMappingList"
            :selectedRowKeys.sync="selectedRowKeys"
            :showSelect="true"
            :showPagination="false"
            :showSetting="false"
          >
            <template v-slot:value="{ record }">
              <a-input
                readOnly
                :value="record.accountingDimensionValueName"
                @click="showDimensionValueModal(record)"
              />
            </template>
          </CommonTable>
        </a-col>
        <a-col :span="6" style="display: flex; justify-content: flex-end; align-items: flex-end">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      v-show="showTable"
      ref="CommonTableRef"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :scroll="{ x: 1500, y: scrollHeight }"
      :total="total"
      rowKey="id"
      @search="getList"
    >
      <template v-slot:debitAmount="{ record }">
        <PriceInput :value.sync="record.debitAmount" :showTxt="true" :currency="record.currency" />
      </template>
      <template v-slot:creditAmount="{ record }">
        <PriceInput :value.sync="record.creditAmount" :showTxt="true" :currency="record.currency" />
      </template>
      <template v-slot:voucherNo="{ text, record }">
        <span class="text-link" @click="goJump(record)">
          {{ $store.getters.journalVoucherTypeMapping[record.voucherType] }} {{ text }}
        </span>
      </template>
    </CommonTable>
    <a-modal
      v-model="visible"
      :title="$t('科目列表')"
      width="1200px"
      @cancel="visible = false"
      @ok="onOk"
    >
      <AccountList ref="AccountListRef" :showSelect="true" @select="onSelect" />
    </a-modal>

    <SelectTableModal
      ref="SelectDimensionModalRef"
      rowKey="value_code"
      api="getAccountDimensionList"
      :selecteds="formData.dimensionMappingList"
      :title="$t('核算维度')"
      :columns="dimensionModalColumns"
      @ok="handleDimensionOk"
    />

    <SelectTableModal
      ref="SelectTableModalRef"
      api="getCommonList"
      :params="{
        tableName: modalData.source_table,
      }"
      :selecteds="modalData.selectedRows"
      :title="modalData.value_name"
      :columns="modalColumns"
      @ok="onSelectModal"
    />
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import SelectTableModal from '@/component/selectTableModal'
import SelectAccountSet from '@/component/selectAccountSet'
import AccountList from '../baseInfo/accountList'
import { convertKeysToCamelCase, jumpSoucePage, generatePeriods } from '@/common'
export default {
  name: 'accountJournalList',
  components: { CommonTable, AccountList, SelectTableModal, SelectAccountSet },
  data() {
    return {
      docType: 'Ledger',
      formData: {
        accountCodeList: [],
        dimensionMappingList: [],
      },
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [],
      dimensionColumns: [],
      periodList: [],

      accountingPeriodStart: undefined,
      accountingPeriodEnd: undefined,
      accountName: undefined,
      visible: false,

      dimensionModalColumns: [],

      modalData: {},
      modalColumns: [],
      showTable: false,
    }
  },
  mounted() {
    this.setColumns()
    this.getPeriodList()
  },
  activated() {
    let query = localStorage.getItem('accountJournalDimensionInfo')
    if (query) {
      query = JSON.parse(query)
      this.accountName = query.accountName
      this.formData.accountCodeList = [query.accountCode]
      this.formData.businessUnitCode = query.businessUnitCode
      this.formData.accountSetCode = query.accountSetCode
      this.formData.accountSetUnionCode = query.accountSetCode + '-' + query.businessUnitCode
      this.selectedRows = [
        {
          code: query.accountCode,
          name: query.accountName,
        },
      ]
      if (query.accountingPeriodStart) {
        this.accountingPeriodStart = query.accountingPeriodStart
        this.accountingPeriodEnd = query.accountingPeriodEnd
      }
      if (query.accountingDimensionCode) {
        this.formData.dimensionMappingList = [
          {
            source_table: query.sourceTable,
            name: query.accountingDimensionName,
            value_code: query.accountingDimensionCode,
            accountingDimensionValueCodeList: [query.accountingDimensionValueCode],
            accountingDimensionValueName: query.accountingDimensionValueName,
          },
        ]
      }
      this.searchHandle()
      localStorage.removeItem('accountJournalDimensionInfo')
    }
  },
  computed: {
    scrollHeight() {
      return window.innerHeight - 270
    },
    periodListStart() {
      let list = this.periodList
      if (this.accountingPeriodEnd) {
        list = list.filter(
          (item) =>
            moment(item.accounting_period_code).isBefore(this.accountingPeriodEnd) ||
            item.accounting_period_code === this.accountingPeriodEnd
        )
      }
      return list
    },
    periodListEnd() {
      let list = this.periodList
      if (this.accountingPeriodStart) {
        list = list.filter(
          (item) =>
            moment(item.accounting_period_code).isAfter(this.accountingPeriodStart) ||
            item.accounting_period_code === this.accountingPeriodStart
        )
      }
      return list
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.setColumns()
    },
    searchHandle(pageNo) {
      if (this.formData.dimensionMappingList.length === 0) {
        return this.$message.warning(this.$t('请选择核算维度'))
      }
      this.showTable = true
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('科目编码'),
          dataIndex: 'accountCode',
        },
        {
          title: this.$t('科目名称'),
          dataIndex: 'accountName',
        },
        {
          title: this.$t('会计期间'),
          dataIndex: 'accountingPeriodName',
        },
        {
          title: this.$t('记账日期'),
          dataIndex: 'accountingDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('凭证字号'),
          dataIndex: 'voucherNo',
          scopedSlots: { customRender: 'voucherNo' },
        },
        {
          title: this.$t('摘要'),
          dataIndex: 'summary',
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimensionListName',
          width: 250,
        },
        {
          title: this.$t('借方金额'),
          dataIndex: 'debitAmount',
          scopedSlots: {
            customRender: 'debitAmount',
          },
          width: 120,
        },
        {
          title: this.$t('贷方金额'),
          dataIndex: 'creditAmount',
          scopedSlots: {
            customRender: 'creditAmount',
          },
          width: 120,
        },
        {
          title: this.$t('余额方向'),
          dataIndex: 'balanceDirection',
          width: 80,
          customRender: (text) => this.$store.getters.balanceDirectionMapping[text],
        },
        {
          title: this.$t('余额'),
          dataIndex: 'balance',
          customRender: (text, record) => this.$currencySymbol(text, record.currency),
          width: 150,
        },
      ]

      this.dimensionColumns = [
        {
          title: this.$t('核算维度'),
          dataIndex: 'name',
        },
        {
          title: this.$t('值'),
          dataIndex: 'value',
          scopedSlots: {
            customRender: 'value',
          },
        },
      ]

      this.dimensionModalColumns = [
        {
          title: this.$t('编码'),
          dataIndex: 'value_code',
        },
        {
          title: this.$t('名称'),
          dataIndex: 'name',
        },
        {
          title: this.$t('值来源'),
          dataIndex: 'value_name',
        },
      ]

      this.modalColumns = [
        {
          title: this.$t('编码'),
          dataIndex: 'code',
        },
        {
          title: this.$t('名称'),
          dataIndex: 'name',
        },
      ]
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
    },
    getPeriodList() {
      http({
        url: api.queryAccountingCalendarDetailPage,
        data: {},
        success: (res) => {
          this.periodList = res.result.list
        },
      })
    },
    async getList(params) {
      this.loading = true
      const data = {
        ...params,
      }
      data.businessUnitCode = this.formData.businessUnitCode
      if (this.formData.accountCodeList.length > 0) {
        data.accountCodeList = this.formData.accountCodeList
      }
      if (this.formData.dimensionMappingList.length > 0) {
        let dimensionMappingList = []
        this.formData.dimensionMappingList.forEach((item) => {
          if (item.accountingDimensionValueCodeList?.length > 0) {
            item.accountingDimensionValueCodeList.forEach((elem) => {
              dimensionMappingList.push({
                accountingDimensionCode: item.value_code,
                accountingDimensionValueCode: elem,
              })
            })
          } else {
            dimensionMappingList.push({
              accountingDimensionCode: item.value_code,
            })
          }
        })
        data.dimensionMappingList = dimensionMappingList
      }
      if (this.accountingPeriodStart && this.accountingPeriodEnd) {
        data.accountingPeriodList = generatePeriods(
          this.accountingPeriodStart,
          this.accountingPeriodEnd
        )
      }
      await http({
        url: api.getAccountJournalDimensionList,
        data,
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list.map((item) => {
            item.dimensionListName =
              item.accountingDimensionName + ': ' + item.accountingDimensionValueName
            if (item.direction === 'CREDIT') {
              item.creditAmount = item.amount
            } else {
              item.debitAmount = item.amount
            }
            item.accountingPeriodName =
              item.accountingPeriod.replace('-', this.$t('年')) + this.$t('期')
            return item
          })
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    goJump(record) {
      jumpSoucePage(record.voucherSn, record.voucherId)
    },
    showAccountModal() {
      this.visible = true
    },
    onSelect(list) {
      this.selectedRows = list
    },
    onOk() {
      this.visible = false
      this.formData.accountCodeList = this.selectedRows.map((item) => item.code)
      this.accountName = this.selectedRows.map((item) => item.full_name).join(';')
      this.searchHandle(1)
    },
    showDimensionModal() {
      this.$refs.SelectDimensionModalRef.open()
    },
    deleteDimension() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.dimensionMappingList = this.formData.dimensionMappingList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      this.selectedRowKeys = []
    },
    handleDimensionOk(list) {
      this.formData.dimensionMappingList = list.map((item) => {
        this.$set(item, 'accountingDimensionValueCode', item.accountingDimensionValueCode || '')
        this.$set(item, 'accountingDimensionValueName', item.accountingDimensionValueName || '')
        this.$set(item, 'selectedRows', item.selectedRows || [])
        return item
      })
    },
    showDimensionValueModal(record) {
      this.modalData = record
      this.$nextTick(() => {
        this.$refs.SelectTableModalRef.open()
      })
    },
    onSelectModal(list) {
      this.modalData.selectedRows = list
      this.modalData.accountingDimensionValueCodeList = list.map((item) => item.code)
      this.modalData.accountingDimensionValueName = list.map((item) => item.name).join('; ')
    },
  },
}
</script>
<style scoped>
.option-btns {
  display: flex;
  justify-content: flex-end;
}
.dimension-table :deep(.ant-empty-normal) {
  margin: 0;
}
</style>
