import { render, staticRenderFns } from "./accountJournalDimensionList.vue?vue&type=template&id=12925289&scoped=true"
import script from "./accountJournalDimensionList.vue?vue&type=script&lang=js"
export * from "./accountJournalDimensionList.vue?vue&type=script&lang=js"
import style0 from "./accountJournalDimensionList.vue?vue&type=style&index=0&id=12925289&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12925289",
  null
  
)

export default component.exports